(function(w, d) {

    var stick_in_parent = function(region, opts) {
        var enable_bottoming, fn, force_sticky, inner_scrolling, offset_top, outer_width, parent_selector, recalc_every, spacer, sticky_class;

        if (opts == null || opts.spacer == null) {
            throw 'Spacer is not defined';
        }

        recalc_every = opts.recalc_every;
        spacer = opts.spacer;

        enable_bottoming = (opts.bottoming == null) ? true : false;
        force_sticky = (opts.force_sticky == null) ? true : false;
        inner_scrolling = (opts.inner_scrolling == null) ? true : false;
        offset_top = (opts.offset_top == null) ? 0 : opts.offset_top;
        parent_selector = (opts.parent == null) ? void 0 : opts.parent;
        sticky_class = (opts.sticky_class == null) ? 'is_stuck' : opts.sticky_class;

        outer_width = function(el) {
            var width, computed;

            if (w.getComputedStyle) {
                computed = getComputedStyle(el);
                width = parseFloat(computed.width) + parseFloat(computed.marginLeft) + parseFloat(computed.marginRight);
                if (computed.boxSizing !== "border-box") {
                    width += parseFloat(computed.borderLeftWidth) + parseFloat(computed.borderRightWidth) + parseFloat(computed.paddingLeft) + parseFloat(computed.paddingRight);
                }
                return width;
            } else {
                width = el.offsetWidth;
                width += parseInt(getComputedStyle(el).marginLeft) + parseInt(getComputedStyle(el).marginRight);
                return width;
            }
        };

        fn = function(elm, padding_bottom, parent_top, parent_height, top, height, el_float, detached, direct_parent_offset) {
            var bottomed, detach, fixed, last_pos, last_scroll_height, offset, parent, recalc, recalc_and_tick, recalc_counter, tick;

            if (elm.dataset.stickyKit) {
                return;
            }

            parent = elm.parentNode;
            if (parent_selector != null) {
                parent = parent_selector;
            }
            if (!parent.childNodes.length) {
                throw 'Failed to find stick parent';
            }

            bottomed = false;
            fixed = false;
            last_scroll_height = d.body.clientHeight;
            spacer.style.position = getComputedStyle(elm).position;

            recalc = function() {
                var border_top, padding_top, restore, parent_top_bounds, spacer_parent_top_bounds, elm_parent_top_bounds;

                if (detached) {
                    return;
                }

                // make sure element is visible
                if (elm.offsetHeight == 0) {
                    return;
                }
                
                parent_top_bounds = parent.getBoundingClientRect().top;
                spacer_parent_top_bounds = spacer.parentNode.getBoundingClientRect().top;
                elm_parent_top_bounds = elm.parentNode.getBoundingClientRect().top;

                last_scroll_height = d.body.clientHeight;
                border_top = parseInt(getComputedStyle(parent).borderTopWidth, 10);
                padding_top = parseInt(getComputedStyle(parent).paddingTop, 10);
                padding_bottom = parseInt(getComputedStyle(parent).paddingBottom, 10);
                parent_top = parent_top_bounds + w.pageYOffset + border_top + padding_top;
                parent_height = parseInt(getComputedStyle(parent).height);
                direct_parent_offset = (spacer != false) ? spacer_parent_top_bounds - parent_top_bounds : elm_parent_top_bounds - parent_top_bounds;

                if (fixed) {
                    fixed = false;
                    bottomed = false;

                    elm.style.position = '';
                    elm.style.top = '';
                    elm.style.width = '';
                    elm.style.bottom = '';
                    elm.classList.remove(sticky_class);

                    restore = true;
                }

                top = (elm.getBoundingClientRect().top + w.pageYOffset) - (parseInt(getComputedStyle(elm).marginTop, 10) || 0) - offset_top;
                height = elm.offsetHeight + parseInt(getComputedStyle(elm).marginTop) + parseInt(getComputedStyle(elm).marginBottom);
                el_float = getComputedStyle(elm).float;

                if (spacer) {
                    spacer.style.width = outer_width(elm) + 'px';
                    spacer.style.height = height + 'px';
                    spacer.style.display = getComputedStyle(elm).display;
                    spacer.style.verticalAlign = getComputedStyle(elm).verticalAlign;
                    spacer.style.float = el_float;
                }
                if (restore) {
                    return tick();
                }
            };

            recalc();

            if (force_sticky != true && height === parent_height) {
                return;
            }

            last_pos = void 0;
            offset = offset_top;
            recalc_counter = recalc_every;

            tick = function() {
                var delta, recalced, scroll, will_bottom, win_height;

                if (detached) {
                    return;
                }

                recalced = false;
                if (recalc_counter != null) {
                    recalc_counter -= 1;
                    if (recalc_counter <= 0) {
                        recalc_counter = recalc_every;
                        recalc();
                        recalced = true;
                    }
                }
                if (!recalced && d.body.clientHeight !== last_scroll_height) {
                    recalc();
                    recalced = true;
                }

                scroll = w.pageYOffset;
                if (last_pos != null) {
                    delta = scroll - last_pos;
                }
                last_pos = scroll;

                if (fixed) {
                    if (enable_bottoming) {
                        will_bottom = scroll + height + offset > parent_height + parent_top;
                        if (bottomed && !will_bottom) {
                            bottomed = false;
                            elm.style.position = 'fixed';
                            elm.style.bottom = '';
                            elm.style.top = offset + 'px';

                            var unbottomEvent;
                            if (w.CustomEvent) {
                                unbottomEvent = new CustomEvent('sticky_kit:unbottom');
                            } else {
                                unbottomEvent = d.createEvent('CustomEvent');
                                event.initCustomEvent('sticky_kit:unbottom', true, true);
                            }
                            elm.dispatchEvent(unbottomEvent);
                        }
                    }
                    if (scroll < top) {
                        fixed = false;
                        offset = offset_top;

                        elm.style.position = '';
                        elm.style.width = '';
                        elm.style.top = '';
                        elm.classList.remove(sticky_class);

                        var unstickEvent;
                        if (w.CustomEvent) {
                            unstickEvent = new CustomEvent('sticky_kit:unstick');
                        } else {
                            unstickEvent = d.createEvent('CustomEvent');
                            event.initCustomEvent('sticky_kit:unstick', true, true);
                        }
                        elm.dispatchEvent(unstickEvent);
                    }
                    if (inner_scrolling) {
                        win_height = w.innerHeight || d.documentElement.clientHeight;

                        if (height + offset_top > win_height) {
                            if (!bottomed) {
                                offset -= delta;
                                offset = Math.max(win_height - height, offset);
                                offset = Math.min(offset_top, offset);
                                if (fixed) {
                                    elm.style.top = offset + 'px';
                                }
                            }
                        }
                    }
                } else {
                    if (scroll > top) {
                        fixed = true;

                        elm.style.width = (getComputedStyle(elm).boxSizing === 'border-box') ? elm.offsetWidth + 'px' : getComputedStyle(elm).width;
                        elm.style.position = 'fixed';
                        elm.style.top = offset + 'px';
                        elm.classList.add(sticky_class);

                        var stickEvent;
                        if (w.CustomEvent) {
                            stickEvent = new CustomEvent('sticky_kit:stick');
                        } else {
                            stickEvent = d.createEvent('CustomEvent');
                            event.initCustomEvent('sticky_kit:stick', true, true);
                        }
                        elm.dispatchEvent(stickEvent);
                    }
                }
                if (fixed && enable_bottoming) {
                    if (will_bottom == null) {
                        will_bottom = scroll + height + offset > parent_height + parent_top;
                    }
                    if (!bottomed && will_bottom) {
                        bottomed = true;

                        elm.style.position = 'absolute';
                        elm.style.top = (parent_height - height - direct_parent_offset) + 'px';

                        var bottomEvent;
                        if (w.CustomEvent) {
                            bottomEvent = new CustomEvent('sticky_kit:bottom');
                        } else {
                            bottomEvent = d.createEvent('CustomEvent');
                            event.initCustomEvent('sticky_kit:bottom', true, true);
                        }
                        elm.dispatchEvent(bottomEvent);
                    }
                }
            };

            recalc_and_tick = function() {
                recalc();
                return tick();
            };

            detach = function() {
                detached = true;

                w.removeEventListener('touchmove', tick);
                w.removeEventListener('scroll', tick);
                w.removeEventListener('resize', recalc_and_tick);

                d.body.removeEventListener('sticky_kit:recalc', recalc_and_tick);

                elm.removeEventListener('sticky_kit:detach', detach);
                elm.removeAttribute('data-sticky-kit');
                elm.style.position = '';
                elm.style.bottom = '';
                elm.style.top = '';
                elm.style.width = '';

                if (fixed) {
                    return elm.classList.remove(sticky_class);
                }
            };

            w.addEventListener('touchmove', tick);
            w.addEventListener('scroll', tick);
            w.addEventListener('resize', recalc_and_tick);

            d.body.addEventListener('sticky_kit:recalc', recalc_and_tick);

            elm.addEventListener('sticky_kit:detach', detach);
            elm.setAttribute('data-sticky-kit', true);

            return setTimeout(tick, 0);
        };

        fn(region);
    };

    w.stick_in_parent = stick_in_parent;

})(window, document);